<template>
  <b-overlay :show="loading">
    <b-row class="mt-5 match-height">
      <b-col v-for="(stats, index) in dashboardStats" :key="`stats-${index}`" md="3">
        <b-card>
           <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="primary"
                >
                  <feather-icon
                    size="24"
                    :icon="stats.icon"
                  />
                </b-avatar>
              </b-media-aside>

              <b-media-body class="my-auto">
                <b-card-text class="font-small-3 mb-0 text-right">
                  {{ stats.title }}
                </b-card-text>
                <h1 class="font-weight-bolder mb-0 text-right">
                  {{ stats.subtitle }}
                </h1>
              </b-media-body>
            </b-media>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="">
      <b-col class="mb-2" cols="12" md="12">
        <b-row class="match-height">
          <b-col md="4">
            <b-card
              no-body
            >
              <b-card-header>
                <h4 class="mb-0">
                  CV Overall Score
                </h4>
              </b-card-header>

              <!-- apex chart -->
              <vue-apex-charts
                type="radialBar"
                height="245"
                class="my-2"
                :options="cvGoalOptions"
                :series="[cvScore]"
              />
              <b-row class="text-center mx-0">
                <b-col
                  cols="12"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    Completing your CV will boost your profile to employers.
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'

import { get } from "lodash"
import { $themeColors } from '@themeConfig'

import { 
  BOverlay, BRow, BCol, BAlert, BLink, BButton, BImg, BCardSubTitle,
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, 
} from 'bootstrap-vue'

import Buy from '@/assets/svg/Buy.svg'

import ExtraCash from "@/pages/public/svgs/extra-cash.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    Buy,
    flatPickr,

    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BAlert,
    BMedia,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    BCardBody,
    ExtraCash,
    BCardTitle,
    BMediaBody,
    BCardHeader,
    BMediaAside,
    VueApexCharts,
    BCardSubTitle,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      loading: false,
      loanProducts: [],
      dashboardStats: [
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: 'Interviews Requested',
          subtitle: '0',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PhoneIcon',
          color: 'light-primary',
          title: 'Contacts Requested',
          subtitle: '0',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'EyeIcon',
          color: 'light-primary',
          title: 'Number Of Views',
          subtitle: '0',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 'Letters Requested',
          subtitle: '0',
          customClass: 'mb-2 mb-xl-0',
        },
      ],
      rangePicker: ['2019-05-01', '2019-05-10'],
      cvGoalOptions: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    cvScore: {
      get() {
        return parseFloat(this.$store.getters[`jobSeeker/cvScore`]);
      },
    },
  },
  created(){
    this.fetchDashboardData();
  },
  methods: {
    async fetchDashboardData(){
      try {
        this.loading = true; 

        const request = await this.useJwt().clientService.fetchDashboardData();
        const { data } = request.data;
        
        this.dashboardStats = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';

  .banner {
    height: 200px;
  }

  .service-card {
    width: 170px;
    min-width: 13vw;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .-ellipsis-2 {
    overflow: hidden;
    /* text-overflow: -o-ellipsis-lastline; */
    text-overflow: ellipsis;
    /* display: block; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.824em;
  }

</style>
